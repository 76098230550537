<template>
  <v-container
    id="google-maps"
    fluid
    tag="section"
    class="relative overflow-hidden"
    style="padding:0px"
  >
    <GoogleMap 
       :latitude="-33.822321" :longitude="-69.963254"
       :title="'Mapa'"
       style=""
       v-on:selectedPuente="showMarkerBridge"
     />
    <NavDashboard />
    <MarkerBridge
      :selectedPuente = "selectedPuente"
      :toggleDialog = "toggleDialog" 
      @close="toggleDialog=false"
      v-on:closeDialog="closeDialog"
    />

    <v-snackbar
      v-model="snackbar"
      timeout="5000"
    >
      No hay datos disponibles

      <template v-slot:action="{ attrs }">
        <v-btn
          color="red"
          text
          v-bind="attrs"
          @click="snackbar = false"
        >
          Cerrar
        </v-btn>
      </template>
    </v-snackbar>

<!--
    <v-tooltip content-class="pa-0 ma-0" top>      
      <v-card v-if="toggleTooltip" class="mx-auto my-0" max-width="344" outlined>
        <v-row>
          <v-col cols="6">
            <v-img
              height="200"
              src="https://i.imgur.com/f656uB3.jpg"
            ></v-img>
          </v-col>
          <v-col cols="6">
            <v-card-text class="pa-0 ma-0">
              <v-card-title class="px-0 my-0">Puente 1</v-card-title>
              <v-card-subtitle class="px-0 my-0">Río 1</v-card-subtitle>
              <p class="pe-2">Lorem ipsum dolor sit amet consectetur adipisicing elit. Temporibus?</p>
            </v-card-text>
          </v-col>
        </v-row>
      </v-card>
    </v-tooltip>
-->

    <!-- 
    <v-row>
      <v-col cols="12">
        <GoogleMap :latitude="-33.822321" :longitude="-69.963254" :title="'Mapa'" />
        <NavDashboard />
        <MarkerBridge />
      </v-col>
    </v-row>
    -->

  </v-container>
</template>

<script>
import Vue from 'vue'
import NavDashboard from "@/components/dev/NavDashboard.vue";
import GoogleMap from "@/components/dev/GoogleMap";
import MarkerBridge from "@/components/dev/MarkerBridge.vue"
import NavDrawer from '../../../components/dev/NavDrawer.vue';
import { FETCH_SENSORS } from "@/store/actions.type";
import { SET_SNACKBAR } from "@/store/mutations.type";
export default {
  components: {
    NavDashboard,
    GoogleMap,
    MarkerBridge,
    NavDrawer
  },
  data () {
    return {
      zoom: 2,
      center: [0, 0],
      rotation: 0,
      geolocPosition: undefined,
      selectedPuente: {},
      toggleDialog: false,
      toggleTooltip: false,
      tooltipData: {}
    }
  },
  created: function() {

      //Buscamos alertas asociadas al usuario

      //IMPORTANTE TO DO:
      //Primero, la conexion al websocket y la recuperacion de alertas se está ejecutando cada vez que se carga el mapa
      //En realidad, esto debería estar condicionado a si estos elementos están definidos en el store de vue
      //Entonces, modificar lo siguiente:

      //1.- Ejecutar recuperacion de alertas SSI el arreglo de alertas en el store es vacio 
      //(incluso, mejor usar una variable que valide si esto se ejecutó alguna vez en la sesión del usuario. Por ej. un usuario podría estar en un puente que aún no tenga alertas)
      //2.- Almacenar conexion a websocket en store y solo crearla en el caso de que este valor sea nulo

      console.log('Se recuperan las alertas')
      Vue.axios.get('getnotifications/')
        .then((response) => {
          console.log('')
          console.log(response.data);
          var thisNotificationsUser = response.data
          this.$store.commit(
            'setNotificationsUser',
            thisNotificationsUser,
          );
        })
        .catch((error) => {
            this.error = true
            throw new Error(`API ${error}`)
        })

      console.log("Starting connection to WebSocket Server")
      var this_jwt = this.$store.state.jwt;
      console.log(this_jwt);
      
      this.connection = new WebSocket("wss://lvs8338c53.execute-api.us-west-2.amazonaws.com/dev?token="+this_jwt);

      this.connection.onmessage = event => {
        console.log('Nuevo evento!');
        var data = JSON.parse(event.data);

        var event_type = data.type;
        var this_value = false;
        var idpuente = false;
        var idsensor = false;

        if (event_type == 'last_values'){
          var idpuente = data.bridgeId;
          var idsensor = data.sensorId;
          var this_value = data.last_cotaterreno;

          if(this_value){
            var puenteslist = this.$store.state.puentes;
            var puentedata = false;
            for(var i = 0; i < puenteslist.length; i++){
              var this_id = puenteslist[i].id;
              if(idpuente == this_id){
                puentedata = puenteslist[i];
              }
            }
            if(puentedata){
              var puentesensores = puentedata.sensores;
              for(var i = 0; i < puentesensores.length; i++){
                if(puentesensores[i].id == idsensor){
                  this.$store.commit("updateSensorLastValue", {'idpuente': idpuente, 'idsensor': idsensor, 'last_value': this_value});
                }
              }
              console.log('SE EMITE ACTUALIZACION ESTADO PUENTE');
              this.$store.commit("updatePuenteStatus", {'id': idpuente});
              //console.log(status)
            }
            else{
              console.log('PUENTE NO ENCONTRADO. ERROR CRITICO');
              console.log(idpuente)
            }
          }
          else{
            console.log('WS. ERROR CRITICO');
            console.log(this_value);
          }
        }
        
        if (event_type == 'new_alert'){
          console.log('Nueva alerta!');
          console.log(data);
          var this_alert = {};
          this_alert.bridgeId = data.bridgeId;
          this_alert.bridgeName = data.bridgeName;
          this_alert.cota = data.cota;
          this_alert.deviceId = data.deviceId;
          this_alert.measurementTime = data.measurementTime;
          this_alert.pileId = data.pileId;
          this_alert.read_status = data.read_status;
          this_alert.status_tag = data.status_tag;
          this.$store.commit("updateAlerts", this_alert);
        }

      }

      this.connection.onopen = function(event) {
        console.log(event);
        console.log("Successfully connected to the echo websocket server...");
      }
  },
  methods: {
    showMarkerBridge (value) {
      console.log('HOLA MUNDO COMPONENTE HIJO');
      console.log(value);
      this.toggleDialog = value.toggle;
      this.selectedPuente = value.data;
    },
    closeDialog(){
      console.log('SE CIERRA DIALOG PADRE');
      this.toggleDialog = false;
    }
  },
  watch: {
    selectedPuente (newValue, oldValue) {
      if (newValue !== oldValue && this.selectedPuente) {
        this.$store.dispatch(FETCH_SENSORS, this.selectedPuente.id)
      }
    }
  },
  computed: {
    snackbar: {
        get () {
          return this.$store.state.showSnackBar
        },
        set (value) {
          this.$store.commit(SET_SNACKBAR, value)
        },
      },
  }
}
</script>
