<template>
  <v-col class="py-0">
    <v-card elevation="0">
      <v-card-title class="blue--text">
        {{ selectedPuente.rio.nombre }}
      </v-card-title>
      <v-card-text>
        <v-simple-table>
          <template>
            <tbody>
              <tr>
                <td class="d-flex align-center justify-space-between">
                  <span class="font-weight-bold">Caudal medio anual: </span>
                  <p class="mb-0">{{ selectedPuente.rio.caudal_medio_anual }}</p>
                </td>
              </tr>
              <!--<tr>
                <td class="d-flex align-center justify-space-between">
                  <span class="font-weight-bold"
                    >Figura de la cuenca aportante:
                  </span>
                  <p class="mb-0">{{ selectedPuente.rio.figura_cuenca_aportante }}</p>
                </td>
              </tr>-->
              <tr>
                <td class="d-flex align-center justify-space-between">
                  <span class="font-weight-bold">Tipo de lecho: </span>
                  <p class="mb-0">{{ selectedPuente.rio.tipo_lecho }}</p>
                </td>
              </tr>
              <tr>
                <td class="d-flex align-center justify-space-between">
                  <span class="font-weight-bold"
                    >Clasificación morfológica:
                  </span>
                  <p class="mb-0">{{ selectedPuente.rio.clasf_morfologica }}</p>
                </td>
              </tr>
            </tbody>
          </template>
        </v-simple-table>
      </v-card-text>
    </v-card>
  </v-col>
</template>

<script>
export default {
  props: {
    selectedPuente: Object,
  },
  data() {
    return {
      titulo: "Rio X",
      caudal: "Caudal",
      figura: "Figura",
      lecho: "Lecho",
      morfo: "Recto",
    };
  },
};
</script>

<style>
</style>