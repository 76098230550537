<template>
  <v-col class="py-0 position-relative">
    <v-dialog v-model="dialog" width="auto" content-class="ma-5 dialog-chart">
      <template v-slot:activator="{ on, attrs }">
        <v-card elevation="0">
          <v-simple-table>
            <template v-slot:default>
              <thead>
                <tr>
                  <th class="text-left">Sensores</th>
                  <th class="text-left">Pila</th>
                  <th class="text-left">Espaciamiento</th>
                  <th class="text-left">Cota Offset</th>
                  <th class="text-left">Cota Inicial</th>
                  <th class="text-left">Cota Fundación</th>
                  <th class="text-left">Cota Crítica Media</th>
                  <th class="text-left">Cota Crítica Alta</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="item in sensors" :key="item.id">
                  <td
                    class="cursor-pointer"
                    @click="alert = !alert; clickButton(item)"
                    v-bind="attrs"
                    v-on="on"
                  >
                    {{ item.puente.nombre }}-Pila-{{ item.pila }}
                  </td>
                  <td>{{ item.pila }}</td>
                  <td>{{ item.espaciamiento }}</td>
                  <td>{{ item.cota_offset }}</td>
                  <td>{{ item.cota_inicial }}</td>
                  <td>{{ item.cota_fundacion }}</td>
                  <td>{{ item.cota_critica_media }}</td>
                  <td>{{ item.cota_critica_alta }}</td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>
        </v-card>
      </template>

      <v-card>
        <v-toolbar
            flat
            dark
            color="primary"
          >
          <v-btn
              icon
              dark
              @click="dialog = false"
            >
              <v-icon>mdi-close</v-icon>
          </v-btn>
          <v-toolbar-title>Sensor: {{selectedSensorName}}</v-toolbar-title> 
        </v-toolbar>
        <v-row class="w-inherit card-chart ma-0 pa-6">
          <v-tabs
            v-model="tab"
            class="d-flex justify-center"
          >
            <v-tab class="tab-login">
              Gráfico Socavación
            </v-tab>
            <v-tab class="tab-login">
              Gráfico Cota
            </v-tab>
            <v-tab class="tab-login">
              Descarga Reportes
            </v-tab>
          </v-tabs>
          <v-tabs-items v-model="tab">
            <v-tab-item>
              <v-col v-if="socavationLoader" align="center" class="pa-0" cols="12">
                <br>
                <v-card-text>Cargando Datos</v-card-text>
                <v-progress-linear
                  indeterminate
                  color="yellow darken-2"
                ></v-progress-linear>
                <br>
                <v-progress-linear
                  indeterminate
                  color="green"
                ></v-progress-linear>
                <br>
                <v-progress-linear
                  indeterminate
                  color="teal"
                ></v-progress-linear>
                <br>
                <v-progress-linear
                  indeterminate
                  color="cyan"
                ></v-progress-linear>
              </v-col>
              <v-col v-else class="pa-0" cols="12">
                <v-card-text v-if="socavationData[0].x.length === 0">
                  <div align="center" class="text-h3 grey lighten-2 center">
                    No hay datos disponibles para este sensor.
                  </div>
                </v-card-text>
                <Plotly v-else :data="socavationData" :layout="socavationPlotLayout" :display-mode-bar="true"></Plotly>
              </v-col>
            </v-tab-item>
            <v-tab-item>
              <v-col v-if="heightLoader" align="center" class="pa-0" cols="12">
                <br>
                <v-card-text>Cargando Datos</v-card-text>
                <v-progress-linear
                  indeterminate
                  color="yellow darken-2"
                ></v-progress-linear>
                <br>
                <v-progress-linear
                  indeterminate
                  color="green"
                ></v-progress-linear>
                <br>
                <v-progress-linear
                  indeterminate
                  color="teal"
                ></v-progress-linear>
                <br>
                <v-progress-linear
                  indeterminate
                  color="cyan"
                ></v-progress-linear>
              </v-col>
              <v-col v-else class="pa-0" cols="12">
                <v-card-text v-if="heightData[0].x.length === 0">
                  <div align="center" class="text-h3 grey lighten-2 center">
                    No hay datos disponibles para este sensor.
                  </div>
                </v-card-text>
                <Plotly v-else :data="heightData" :layout="heightPlotLayout" :display-mode-bar="true"></Plotly>
              </v-col>
            </v-tab-item>
            <v-tab-item>
              <v-col v-if="reportLoader" align="center" class="pa-0" cols="12">
                <br>
                <v-card-text>Cargando Datos</v-card-text>
                <v-progress-linear
                  indeterminate
                  color="yellow darken-2"
                ></v-progress-linear>
                <br>
                <v-progress-linear
                  indeterminate
                  color="green"
                ></v-progress-linear>
                <br>
                <v-progress-linear
                  indeterminate
                  color="teal"
                ></v-progress-linear>
                <br>
                <v-progress-linear
                  indeterminate
                  color="cyan"
                ></v-progress-linear>
              </v-col>
              <v-col v-else cols="12">
                <h1>Reportes</h1>
                <p>Seleccione un rango de fechas para descargar los datos del sensor:</p>
                <v-row>
                  <!-- Fecha de inicio reporte -->
                  <v-col
                    cols="12"
                    sm="6"
                    md="4"
                  >
                    <v-dialog
                      ref="dialogStartDate"
                      v-model="modalStartDate"
                      :return-value.sync="startDate"
                      persistent
                      width="290px"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                          v-model="startDate"
                          label="Fecha Inicio"
                          prepend-icon="mdi-calendar"
                          readonly
                          v-bind="attrs"
                          v-on="on"
                        ></v-text-field>
                      </template>
                      <v-date-picker
                        v-model="startDate"
                        scrollable
                      >
                        <v-spacer></v-spacer>
                        <v-btn
                          text
                          color="primary"
                          @click="modalStartDate = false"
                        >
                          Cancel
                        </v-btn>
                        <v-btn
                          text
                          color="primary"
                          @click="$refs.dialogStartDate.save(startDate)"
                        >
                          OK
                        </v-btn>
                      </v-date-picker>
                    </v-dialog>
                  </v-col>
                  <!-- Fecha de término reporte -->
                  <v-col
                    cols="12"
                    sm="6"
                    md="4"
                  >
                    <v-dialog
                      ref="dialogEndDate"
                      v-model="modalEndDate"
                      :return-value.sync="endDate"
                      persistent
                      width="290px"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                          v-model="endDate"
                          label="Fecha Término"
                          prepend-icon="mdi-calendar"
                          readonly
                          v-bind="attrs"
                          v-on="on"
                        ></v-text-field>
                      </template>
                      <v-date-picker
                        v-model="endDate"
                        scrollable
                      >
                        <v-spacer></v-spacer>
                        <v-btn
                          text
                          color="primary"
                          @click="modalEndDate = false"
                        >
                          Cancel
                        </v-btn>
                        <v-btn
                          text
                          color="primary"
                          @click="$refs.dialogEndDate.save(endDate)"
                        >
                          OK
                        </v-btn>
                      </v-date-picker>
                    </v-dialog>
                  </v-col>
                  <!-- Botón Descarga reporte -->
                  <v-col cols="12"
                    sm="6"
                    md="4">
                    <v-btn block color="primary" class="my-1" @click="getReport">Descargar</v-btn>
                  </v-col>
                </v-row>
              </v-col>
            </v-tab-item>
          </v-tabs-items>
          <!-- <v-col class="d-flex flex-column justify-center pa-0">
            <v-row>
              <v-col cols="6">
                <v-btn block color="primary" class="my-1">Socavación</v-btn>
              </v-col>
              <v-col cols="6">
                <v-btn block color="primary" class="my-1">Cota</v-btn>
              </v-col>
              <v-col cols="6">
                <v-btn block color="primary" class="my-1">Descarga Reportes</v-btn>
              </v-col>
            </v-row>
          </v-col> -->
        </v-row>
      </v-card>
    </v-dialog>
  </v-col>
</template>

<script>
import { mapGetters, mapState } from 'vuex';
import { Plotly } from 'vue-plotly'
import { GET_REPORT, GET_SOCAVATION_DATA, GET_HEIGHT_DATA } from '@/store/actions.type';
export default {
  components: {
    Plotly
  },
  data() {
    return {
      modalStartDate: false,
      modalEndDate: false,
      tab: null,
      selectedSensor: null,
      selectedSensorName: null,
      startDate: new Date(new Date().setDate(new Date().getDate() - 7)).toISOString().substr(0, 10),
      endDate: new Date(new Date().setDate(new Date().getDate())).toISOString().substr(0, 10),
      // socavationData:[{
      //   x: [1,2,3,4],
      //   y: [10,15,13,17],
      //   type:"scatter"
      // }],
      socavationPlotLayout:{
        title: "Gráfico de Socavación",
        yaxis: {
          title: 'socavación [m]'
        },
        xaxis: {
          title: 'Fecha'
        }
      },
      // heightData:[{
      //   x: [1,2,3,4],
      //   y: [10,15,13,17],
      //   type:"scatter"
      // }],
      heightPlotLayout:{
        title: "Gráfico de Cota",
        yaxis: {
          title: 'cota [msnm]'
        },
        xaxis: {
          title: 'Fecha'
        }
      },
      dialog: false,
      alert: false,
      labels: ["12", "3", "6", "9", "12", "3", "6"],
      value: [200, 675, 410, 390, 310, 460, 250],
    };
  },
  methods:{
    dialogCenter(){
      let dialog = document.getElementsByClassName("dialog-chart");
      // dialog.style.justifyContent = "center"
      console.log(dialog)
    },
    getReport () {
      this.$store.dispatch(GET_REPORT, {
        "id": this.selectedSensor,
        "startDate": this.startDate,
        "endDate": this.endDate
    })
    },
    clickButton(sensor){
      this.selectedSensor = sensor.id
      this.selectedSensorName = sensor.puente.nombre + '-Pila-' + sensor.pila
    }
  },
  mounted() {
    this.dialogCenter()
  },
  computed: {
    ...mapState({
      sensors: state => state.selectedSensors,
      socavationLoader: state => state.loadingSocavationData,
      heightLoader: state => state.loadingHeightData,
      reportLoader: state => state.loadingReport
    }),
    ...mapGetters(['socavationData', 'heightData'])
  },
  watch: {
    selectedSensor(newValue, oldValue){
      if (newValue !== oldValue) {
        this.$store.dispatch(GET_SOCAVATION_DATA, this.selectedSensor)
        this.$store.dispatch(GET_HEIGHT_DATA, this.selectedSensor)
      }
    }
  }
};
</script>

<style lang="sass" >
@import '../../assets/dev/css/mixin'
.cursor-pointer
  cursor: pointer
.position-relative
  position: relative
.w-inherit
  width: inherit
.dialog-chart
  position: absolute
  right: 180px
  left: 180px
  +for-tablet-landscape-only
    right: 120px
    left: 120px
  +for-tablet-portrait-only
    right: 100px
    left: 100px
  +for-ipad
    position: initial
.card-chart
  +for-ipad
    // width: fit-content
    background-color: white
.charts
  +for-ipad
    height: 150px
  svg
    +for-ipad
      height: 150px
</style>
