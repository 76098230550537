<template>
  <div>
    <v-card-actions class="px-0 py-0 menu-tab">
      <v-list-item-icon class="me-3">
        <v-icon color="white">
          mdi-bridge
        </v-icon>
      </v-list-item-icon>
      <v-btn
        depressed
        dark
        color="rgb(255, 0, 0, 0)"
      >
        <v-list-item-content @click="show = !show">
          <v-list-item-title class="white--text text-subtitle-2">
            Puentes
          </v-list-item-title>
        </v-list-item-content>
      </v-btn>
    </v-card-actions>
    <v-expand-transition>
      <div v-show="show">
        <v-divider></v-divider>
        <v-card-text class="fields pb-0">
          <v-col class="pa-0 ma-0">
            <v-text-field
              v-model="newBridge.nombre"
              class="pa-0 ma-0"
              label="Nombre Puente"
              :error-messages="nameErrors"
              required
              @input="$v.newBridge.nombre.$touch()"
              @blur="$v.newBridge.nombre.$touch()"
            />
            <v-text-field
              v-model="newBridge.latitud"
              class="pa-0 ma-0"
              label="Latitud"
              type="number"
              :error-messages="latitudeErrors"
              required
              @input="$v.newBridge.latitud.$touch()"
              @blur="$v.newBridge.latitud.$touch()"
            />
            <v-text-field
              v-model="newBridge.longitud"
              class="pa-0 ma-0"
              label="Longitud"
              type="number"
              :error-messages="longitudeErrors"
              required
              @input="$v.newBridge.longitud.$touch()"
              @blur="$v.newBridge.longitud.$touch()"
            />
            <!-- <v-row>
              <v-col
                class="pa-0"
                cols="5"
              >
                <v-subheader class="d-flex align-end">
                  Nº de Pilas
                </v-subheader>
              </v-col>
              <v-col
                class="pa-0"
                cols="7"
              >
                <v-text-field
                  outlined
                  value="1"
                  label="Pilas"
                />
              </v-col>
            </v-row> -->
            <v-row>
              <v-col
                class="pa-0"
                cols="5"
              >
                <v-subheader
                  class="d-flex align-end"
                >
                  Largo del puente
                </v-subheader>
              </v-col>
              <v-col
                class="pa-0"
                cols="7"
              >
                <v-text-field
                  v-model="newBridge.largo"
                  outlined
                  label="Largo"
                  suffix="mt"
                  type="number"
                  :error-messages="largeErrors"
                  required
                  @input="$v.newBridge.largo.$touch()"
                  @blur="$v.newBridge.largo.$touch()"
                />
              </v-col>
            </v-row>
            <!-- <v-text-field
              class="pa-0 ma-0"
              label="Profundidad de las fundaciones"
              type="number"
            /> -->
            <v-select
              v-model="newBridge.empresa"
              :items="tenants"
              label="Empresa"
              item-text="empresa.nombre"
              item-value="id"
              :error-messages="companyErrors"
              required
              @input="$v.newBridge.empresa.$touch()"
              @blur="$v.newBridge.empresa.$touch()"
            />
            <v-select
              v-model="newBridge.rio"
              :items="rivers"
              label="Río"
              item-text="nombre"
              item-value="id"
              :error-messages="riverErrors"
              required
              @input="$v.newBridge.rio.$touch()"
              @blur="$v.newBridge.rio.$touch()"
            />
          </v-col>
          <div class="d-flex justify-center">
            <v-btn
              class="mb-4 primary"
              @click="createBridge"
            >
              Agregar
            </v-btn>
          </div>
        </v-card-text>
      </div>
    </v-expand-transition>
  </div>
</template>

<script>
  import { validationMixin } from 'vuelidate'
  import { required } from 'vuelidate/lib/validators'
  import { mapGetters } from 'vuex'
  import { BRIDGE_CREATE } from '@/store/actions.type'
  import { SHOW_SNACKBAR } from '@/store/mutations.type'
  export default {
    mixins: [validationMixin],
    validations: {
      newBridge: {
        nombre: { required },
        latitud: { required },
        longitud: { required },
        largo: { required },
        rio: { required },
        empresa: { required },
      },
    },
    data () {
      return {
        show: false,
        newBridge: {
          nombre: '',
          latitud: '',
          longitud: '',
          largo: '',
          profundidad: '',
          rio: '',
          empresa: '',
        },
      }
    },
    computed: {
      ...mapGetters(['rivers', 'tenants']),
      nameErrors () {
        const errors = []
        if (!this.$v.newBridge.nombre.$dirty) return errors
        !this.$v.newBridge.nombre.required && errors.push('Se requiere el nombre del puente.')
        return errors
      },
      latitudeErrors () {
        const errors = []
        if (!this.$v.newBridge.latitud.$dirty) return errors
        !this.$v.newBridge.latitud.required && errors.push('Se requiere la latitud del puente.')
        return errors
      },
      longitudeErrors () {
        const errors = []
        if (!this.$v.newBridge.longitud.$dirty) return errors
        !this.$v.newBridge.longitud.required && errors.push('Se requiere la longitud del puente.')
        return errors
      },
      largeErrors () {
        const errors = []
        if (!this.$v.newBridge.largo.$dirty) return errors
        !this.$v.newBridge.largo.required && errors.push('Se requiere el largo del puente.')
        return errors
      },
      companyErrors () {
        const errors = []
        if (!this.$v.newBridge.empresa.$dirty) return errors
        !this.$v.newBridge.empresa.required && errors.push('Se requiere seleccionar una empresa.')
        return errors
      },
      riverErrors () {
        const errors = []
        if (!this.$v.newBridge.rio.$dirty) return errors
        !this.$v.newBridge.rio.required && errors.push('Se requiere seleccionar un río.')
        return errors
      },
    },
    methods: {
      createBridge () {
        this.$v.$touch()
        if (this.$v.$invalid) {
          return
        }
        this.$store
          .dispatch(BRIDGE_CREATE, this.newBridge)
          .then(({ data }) => {
            this.newBridge = {
              nombre: '',
              latitud: '',
              longitud: '',
              largo: '',
              profundidad: '',
              rio: '',
              empresa: '',
            }
            this.$store.commit(SHOW_SNACKBAR, {
              msg: 'Puente creado con éxito',
              color: 'success',
              show: true,
            })
            this.$v.$reset()
          })
          .catch(({ response }) => {
            if (response.status === 400) {
              this.$store.commit(SHOW_SNACKBAR, {
                msg: 'El puente ya existe',
                color: 'error',
                show: true,
              })
            } else if (response.status === 403) {
              this.$store.commit(SHOW_SNACKBAR, {
                msg: 'No autorizado.',
                color: 'error',
                show: true,
              })
            } else {
              this.$store.commit(SHOW_SNACKBAR, {
                msg: 'Error interno del servidor',
                color: 'error',
                show: true,
              })
            }
          })
      },
    },
  }
</script>
<style lang="sass">
.fields
  background-color: rgba(255,255,255,0.6)
// .v-input__slot
//   min-height: 45px !important
//   margin-bottom: 0
// .v-text-field__details
//   min-height: 0
//   height: 10px
</style>
