<template>
  <div>
    <v-card-actions class="px-0 py-0 menu-tab">
      <v-list-item-icon class="me-3">
        <v-icon color="white">
          mdi-leak
        </v-icon>
      </v-list-item-icon>
      <v-btn
        depressed
        dark
        color="rgb(255, 0, 0, 0)"
      >
        <v-list-item-content @click="show = !show">
          <v-list-item-title class="white--text text-subtitle-2">
            Sensores
          </v-list-item-title>
        </v-list-item-content>
      </v-btn>
    </v-card-actions>
    <v-expand-transition>
      <div v-show="show">
        <v-divider></v-divider>
        <v-card-text class="fields pb-0">
          <v-col class="pa-0 ma-0 mt-1">
            <v-row>
              <v-col
                class="pa-0"
                cols="5"
              >
                <v-subheader class="d-flex align-end">
                  ID
                </v-subheader>
              </v-col>
              <v-col
                class="pa-0"
                cols="7"
              >
                <v-text-field
                  v-model="newSensor.serial"
                  outlined
                  label="Serial"
                  required
                  :error-messages="serialErrors"
                  @change="$v.newSensor.serial.$touch()"
                  @blur="$v.newSensor.serial.$touch()"
                />
              </v-col>
            </v-row>
            <v-text-field
              v-model="newSensor.pila"
              class="pa-0 ma-0"
              label="Pila"
              required
              :error-messages="pileErrors"
              @change="$v.newSensor.pila.$touch()"
              @blur="$v.newSensor.pila.$touch()"
            />
            <!-- <v-select
              :items="tipo"
              label="Tipo"
            /> -->
            <v-text-field
              v-model="newSensor.posicion"
              class="pa-0 ma-0"
              label="Posición respecto a Pila"
              required
              :error-messages="positionErrors"
              @change="$v.newSensor.posicion.$touch()"
              @blur="$v.newSensor.posicion.$touch()"
            />
            <v-text-field
              v-model="newSensor.prof_fund"
              class="pa-0 ma-0"
              label="Profundidad Pila"
              type="number"
              required
              :error-messages="depthErrors"
              @change="$v.newSensor.prof_fund.$touch()"
              @blur="$v.newSensor.prof_fund.$touch()"
            />
            <v-text-field
              v-model="newSensor.umbral_medio"
              class="pa-0 ma-0"
              label="Umbral Medio"
              type="number"
              min="0"
              required
              :error-messages="mediumThresholdErrors"
              @change="$v.newSensor.umbral_medio.$touch()"
              @blur="$v.newSensor.umbral_medio.$touch()"
            />
            <v-text-field
              v-model="newSensor.umbral_alto"
              class="pa-0 ma-0"
              label="Umbral Alto"
              type="number"
              min="0"
              required
              :error-messages="highThresholdErrors"
              @change="$v.newSensor.umbral_alto.$touch()"
              @blur="$v.newSensor.umbral_alto.$touch()"
            />
            <v-select
              v-model="newSensor.puente_id"
              :items="bridgeList"
              label="Puente"
              item-text="nombre"
              item-value="id"
              required
              :error-messages="bridgeErrors"
              @change="$v.newSensor.puente_id.$touch()"
              @blur="$v.newSensor.puente_id.$touch()"
            />
          </v-col>
          <div class="d-flex justify-center">
            <v-btn
              class="mb-4 primary"
              @click="createSensor"
            >
              Agregar
            </v-btn>
          </div>
        </v-card-text>
      </div>
    </v-expand-transition>
  </div>
</template>

<script>
  import { validationMixin } from 'vuelidate'
  import { required } from 'vuelidate/lib/validators'
  import { mapGetters } from 'vuex'
  import { SENSOR_CREATE } from '@/store/actions.type'
  import { SHOW_SNACKBAR } from '@/store/mutations.type'
  export default {
    mixins: [validationMixin],
    validations: {
      newSensor: {
        puente_id: { required },
        serial: { required },
        pila: { required },
        prof_fund: { required },
        posicion: { required },
        umbral_medio: { required },
        umbral_alto: { required },
      },
    },
    data () {
      return {
        show: false,
        // tipo: ['Tipo1', 'Tipo2', 'Tipo3', 'Tipo4'],
        newSensor: {
          serial: '',
          pila: '',
          posicion: '',
          prof_fund: '',
          umbral_medio: '',
          umbral_alto: '',
          puente_id: '',
        },
      }
    },
    computed: {
      ...mapGetters(['bridgeList']),
      serialErrors () {
        const errors = []
        if (!this.$v.newSensor.serial.$dirty) return errors
        !this.$v.newSensor.serial.required && errors.push('Se requiere la serial del sensor.')
        return errors
      },
      bridgeErrors () {
        const errors = []
        if (!this.$v.newSensor.puente_id.$dirty) return errors
        !this.$v.newSensor.puente_id.required && errors.push('Se requiere seleccionar un puente.')
        return errors
      },
      pileErrors () {
        const errors = []
        if (!this.$v.newSensor.pila.$dirty) return errors
        !this.$v.newSensor.pila.required && errors.push('Se requiere la pila del sensor.')
        return errors
      },
      depthErrors () {
        const errors = []
        if (!this.$v.newSensor.prof_fund.$dirty) return errors
        !this.$v.newSensor.prof_fund.required && errors.push('Se requiere la profundidad de la fundación.')
        return errors
      },
      positionErrors () {
        const errors = []
        if (!this.$v.newSensor.posicion.$dirty) return errors
        !this.$v.newSensor.posicion.required && errors.push('Se requiere la posición del sensor.')
        return errors
      },
      mediumThresholdErrors () {
        const errors = []
        if (!this.$v.newSensor.umbral_medio.$dirty) return errors
        !this.$v.newSensor.umbral_medio.required && errors.push('Se requiere el umbral medio.')
        return errors
      },
      highThresholdErrors () {
        const errors = []
        if (!this.$v.newSensor.umbral_alto.$dirty) return errors
        !this.$v.newSensor.umbral_alto.required && errors.push('Se requiere el umbral alto.')
        return errors
      },
    },
    methods: {
      createSensor () {
        this.$v.$touch()
        if (this.$v.$invalid) {
          return
        }
        this.$store
          .dispatch(SENSOR_CREATE, this.newSensor)
          .then(({ data }) => {
            this.newSensor = {
              serial: '',
              pila: '',
              posicion: '',
              prof_fund: '',
              umbral_medio: '',
              umbral_alto: '',
              puente_id: '',
            }
            this.$store.commit(SHOW_SNACKBAR, {
              msg: 'Sensor creado con éxito',
              color: 'success',
              show: true,
            })
            this.$v.$reset()
          })
          .catch(({ response }) => {
            console.log(response)
            if (response.status === 400) {
              this.$store.commit(SHOW_SNACKBAR, {
                msg: 'El sensor ya existe',
                color: 'error',
                show: true,
              })
            } else if (response.status === 403) {
              this.$store.commit(SHOW_SNACKBAR, {
                msg: 'No autorizado.',
                color: 'error',
                show: true,
              })
            } else {
              this.$store.commit(SHOW_SNACKBAR, {
                msg: 'Error interno del servidor',
                color: 'error',
                show: true,
              })
            }
          })
      },
    },
  }
</script>
<style lang="sass">
.fields
  background-color: rgba(255,255,255,0.6)
// .v-input__slot
//   min-height: 45px !important
//   margin-bottom: 0
// .v-text-field__details
//   min-height: 0
//   height: 10px
</style>
