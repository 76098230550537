<template>
  <div>
    <v-card-actions class="px-0 py-0 menu-tab">
      <v-list-item-icon class="me-3">
        <v-icon color="white">
          mdi-waves
        </v-icon>
      </v-list-item-icon>
      <v-btn
        depressed
        dark
        color="rgb(255, 0, 0, 0)"
      >
        <v-list-item-content @click="show = !show">
          <v-list-item-title class="white--text text-subtitle-2">
            Ríos
          </v-list-item-title>
        </v-list-item-content>
      </v-btn>
    </v-card-actions>
    <v-expand-transition>
      <div v-show="show">
        <v-divider></v-divider>
        <v-card-text class="fields pb-0">
          <v-col class="pa-0 ma-0">
            <v-text-field
              v-model="newRiver.nombre"
              class="pa-0 ma-0"
              label="Nombre"
              required
              :error-messages="nameErrors"
              @change="$v.newRiver.nombre.$touch()"
              @blur="$v.newRiver.nombre.$touch()"
            />
            <v-select
              v-model="newRiver.region"
              :items="regions"
              label="Región"
              item-text="name"
              item-value="number"
              required
              :error-messages="regionErrors"
              @change="$v.newRiver.region.$touch()"
              @blur="$v.newRiver.region.$touch()"
            />
            <v-text-field
              v-model="newRiver.caudal_medio_anual"
              class="pa-0 ma-0"
              label="Caudal"
              type="number"
              min="0"
              required
              :error-messages="caudalErrors"
              @change="$v.newRiver.caudal_medio_anual.$touch()"
              @blur="$v.newRiver.caudal_medio_anual.$touch()"
            />
            <v-text-field
              v-model="newRiver.figura_cuenca_aportante"
              class="pa-0 ma-0"
              label="Figura de la Cuenca"
              required
              :error-messages="figuraErrors"
              @change="$v.newRiver.figura_cuenca_aportante.$touch()"
              @blur="$v.newRiver.figura_cuenca_aportante.$touch()"
            />
            <v-select
              v-model="newRiver.tipo_lecho"
              :items="beds"
              label="Tipo de lecho"
              item-text="name"
              item-value="name"
              required
              :error-messages="lechoErrors"
              @change="$v.newRiver.tipo_lecho.$touch()"
              @blur="$v.newRiver.tipo_lecho.$touch()"
            />
            <v-select
              v-model="newRiver.clasf_morfologica"
              :items="morphologies"
              label="Clasificación morfológica"
              item-text="name"
              item-value="name"
              required
              :error-messages="morfErrors"
              @change="$v.newRiver.clasf_morfologica.$touch()"
              @blur="$v.newRiver.clasf_morfologica.$touch()"
            />
          </v-col>
          <div class="d-flex justify-center">
            <v-btn
              class="mb-4 primary"
              @click="addRiver"
            >
              Agregar
            </v-btn>
          </div>
        </v-card-text>
      </div>
    </v-expand-transition>
  </div>
</template>

<script>
  import { validationMixin } from 'vuelidate'
  import { required, minValue, numeric } from 'vuelidate/lib/validators'
  import { mapGetters } from 'vuex'
  import { RIVER_CREATE } from '@/store/actions.type'
  import { SHOW_SNACKBAR } from '@/store/mutations.type'
  export default {
    mixins: [validationMixin],
    validations: {
      newRiver: {
        nombre: { required },
        region: { required },
        caudal_medio_anual: { required, numeric, minValue: minValue(0) },
        figura_cuenca_aportante: { required },
        clasf_morfologica: { required },
        tipo_lecho: { required },
      },
    },
    data () {
      return {
        show: false,
        pilas: 1,
        newRiver: {
          nombre: null,
          region: null,
          caudal_medio_anual: null,
          figura_cuenca_aportante: null,
          clasf_morfologica: null,
          tipo_lecho: null,
        },
      }
    },
    computed: {
      ...mapGetters(['regions', 'beds', 'morphologies']),
      caudalErrors () {
        const errors = []
        if (!this.$v.newRiver.caudal_medio_anual.$dirty) return errors
        !this.$v.newRiver.caudal_medio_anual.minValue && errors.push('El caudal debe ser un número positivo positivo.')
        !this.$v.newRiver.caudal_medio_anual.numeric && errors.push('El caudal debe ser un número.')
        !this.$v.newRiver.caudal_medio_anual.required && errors.push('Se requiere el caudal.')
        return errors
      },
      regionErrors () {
        const errors = []
        if (!this.$v.newRiver.region.$dirty) return errors
        !this.$v.newRiver.region.required && errors.push('Se requiere la región.')
        return errors
      },
      nameErrors () {
        const errors = []
        if (!this.$v.newRiver.nombre.$dirty) return errors
        !this.$v.newRiver.nombre.required && errors.push('Se requiere el nombre del río.')
        return errors
      },
      morfErrors () {
        const errors = []
        if (!this.$v.newRiver.clasf_morfologica.$dirty) return errors
        !this.$v.newRiver.clasf_morfologica.required && errors.push('Se requiere la clasificación morfológica.')
        return errors
      },
      lechoErrors () {
        const errors = []
        if (!this.$v.newRiver.tipo_lecho.$dirty) return errors
        !this.$v.newRiver.tipo_lecho.required && errors.push('Se requiere el tipo de lecho.')
        return errors
      },
      figuraErrors () {
        const errors = []
        if (!this.$v.newRiver.figura_cuenca_aportante.$dirty) return errors
        !this.$v.newRiver.figura_cuenca_aportante.required && errors.push('Se requiere la figura de la cuenca.')
        return errors
      },
    },
    methods: {
      addRiver () {
        this.$v.$touch()
        if (this.$v.$invalid) {
          return
        }
        this.$store
          .dispatch(RIVER_CREATE, this.newRiver)
          .then(({ data }) => {
            this.newRiver = {
              nombre: null,
              region: null,
              caudal_medio_anual: null,
              figura_cuenca_aportante: null,
              clasf_morfologica: null,
              tipo_lecho: null,
            }
            this.$store.commit(SHOW_SNACKBAR, {
              msg: 'Río creado con éxito',
              color: 'success',
              show: true,
            })
            this.$v.$reset()
          })
          .catch(({ response }) => {
            if (response.status === 400) {
              this.$store.commit(SHOW_SNACKBAR, {
                msg: 'El río ya existe',
                color: 'error',
                show: true,
              })
            } else if (response.status === 403) {
              this.$store.commit(SHOW_SNACKBAR, {
                msg: 'No autorizado.',
                color: 'error',
                show: true,
              })
            } else {
              this.$store.commit(SHOW_SNACKBAR, {
                msg: 'Error interno del servidor',
                color: 'error',
                show: true,
              })
            }
          })
      },
    },
  }
</script>
<style lang="sass">
.fields
  background-color: rgba(255,255,255,0.6)
// .v-input__slot
//   min-height: 45px !important
//   margin-bottom: 0
// .v-text-field__details
//   min-height: 0
//   height: 10px
</style>
