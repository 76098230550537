<template>
  <div>
    <v-card-actions class="px-0 py-0 menu-tab">
      <v-list-item-icon class="me-3">
        <v-icon color="white">
          mdi-plus-circle
        </v-icon>
      </v-list-item-icon>
      <v-btn
        depressed
        dark
        color="rgb(255, 0, 0, 0)"
      >
        <v-list-item-content @click="show = !show">
          <v-list-item-title class="white--text text-subtitle-2">
            Clientes
          </v-list-item-title>
        </v-list-item-content>
      </v-btn>
    </v-card-actions>
    <v-expand-transition>
      <div v-show="show">
        <v-divider></v-divider>
        <v-card-text class="fields pb-0">
          <v-col class="pa-0 ma-0">
            <form>
              <v-text-field
                v-model="newTenant.empresa.nombre"
                class="pa-0 ma-0"
                label="Nombre Empresa"
                :error-messages="companyErrors"
                required
                @input="$v.newTenant.empresa.nombre.$touch()"
                @blur="$v.newTenant.empresa.nombre.$touch()"
              />
              <v-text-field
                v-model="newTenant.nombre"
                class="pa-0 ma-0"
                label="Nombre"
                :error-messages="nameErrors"
                required
                @input="$v.newTenant.nombre.$touch()"
                @blur="$v.newTenant.nombre.$touch()"
              />
              <v-text-field
                v-model="newTenant.email"
                class="pa-0 ma-0"
                label="E-Mail"
                :error-messages="emailErrors"
                required
                @input="$v.newTenant.email.$touch()"
                @blur="$v.newTenant.email.$touch()"
              />
              <v-text-field
                v-model="newTenant.telefono"
                class="pa-0 ma-0"
                label="Teléfono"
                :error-messages="phoneErrors"
                required
                @input="$v.newTenant.telefono.$touch()"
                @blur="$v.newTenant.telefono.$touch()"
              />
              <div class="d-flex justify-center form-group">
                <v-btn
                  class="mb-4 primary"
                  @click="createTenant"
                >
                  Agregar
                </v-btn>
              </div>
            </form>
          </v-col>
        </v-card-text>
      </div>
    </v-expand-transition>
  </div>
</template>

<script>
  import { validationMixin } from 'vuelidate'
  import { required, email } from 'vuelidate/lib/validators'
  import { TENANT_CREATE } from '@/store/actions.type'
  import { SHOW_SNACKBAR } from '@/store/mutations.type'
  export default {
    mixins: [validationMixin],
    validations: {
      newTenant: {
        nombre: { required },
        email: { required, email },
        telefono: { required },
        empresa: {
          nombre: { required },
        },
      },
    },
    data () {
      return {
        show: false,
        newTenant: {
          nombre: '',
          email: '',
          telefono: '',
          empresa: {
            nombre: '',
          },
        },
      }
    },
    computed: {
      nameErrors () {
        const errors = []
        if (!this.$v.newTenant.nombre.$dirty) return errors
        !this.$v.newTenant.nombre.required && errors.push('Se requiere el nombre del contacto.')
        return errors
      },
      companyErrors () {
        const errors = []
        if (!this.$v.newTenant.empresa.nombre.$dirty) return errors
        !this.$v.newTenant.empresa.nombre.required && errors.push('Se requiere el nombre de la empresa.')
        return errors
      },
      phoneErrors () {
        const errors = []
        if (!this.$v.newTenant.telefono.$dirty) return errors
        !this.$v.newTenant.telefono.required && errors.push('Se requiere el teléfono de contacto.')
        return errors
      },
      emailErrors () {
        const errors = []
        if (!this.$v.newTenant.email.$dirty) return errors
        !this.$v.newTenant.email.email && errors.push('Debe ser un email válido.')
        !this.$v.newTenant.email.required && errors.push('Se requiere el email de contacto.')
        return errors
      },
    },
    methods: {
      createTenant () {
        this.$v.$touch()
        if (this.$v.$invalid) {
          return
        }
        this.$store
          .dispatch(TENANT_CREATE, this.newTenant)
          .then(({ data }) => {
            this.newTenant = {
              nombre: '',
              email: '',
              telefono: '',
              empresa: {
                nombre: '',
              },
            }
            this.$store.commit(SHOW_SNACKBAR, {
              msg: 'Cliente creado con éxito',
              color: 'success',
              show: true,
            })
            this.$v.$reset()
          })
          .catch(({ response }) => {
            if (response.status === 400) {
              this.$store.commit(SHOW_SNACKBAR, {
                msg: 'El cliente ya existe',
                color: 'error',
                show: true,
              })
            } else if (response.status === 403) {
              this.$store.commit(SHOW_SNACKBAR, {
                msg: 'No autorizado.',
                color: 'error',
                show: true,
              })
            } else {
              this.$store.commit(SHOW_SNACKBAR, {
                msg: 'Error interno del servidor',
                color: 'error',
                show: true,
              })
            }
          })
      },
    },
  }
</script>
<style lang="sass">
.fields
  background-color: rgba(255,255,255,0.6)
// .v-input__slot
//   min-height: 45px !important
//   margin-bottom: 0
// .v-text-field__details
//   min-height: 0
//   height: 10px
</style>
