<template>
  <gmap-map
    :center="center"
    :zoom="5"
    :mapTypeId="mapTypeId"
    :options="mapOptions"
    style="width: 100%; min-height: 100vh;"
    ref="mapRef"
  >
    <gmap-marker
      :key="index"
      v-for="(m, index) in markers"
      :position="m.position"
      :title="m.title"
      :icon="getMarkerIcon(m.status)"
      :clickable="true"
      :draggable="false"
      v-on:click="center=m.position; selectPuente(m);"
      v-on:mouseover="showTooltip(m);"
      v-on:mouseout="hideTooltip(m);"
    ></gmap-marker>

    <gmap-info-window
        :options="{
          maxWidth: 300,
          pixelOffset: { width: 0, height: -35 }
        }"
        :position="infoWindow.position"
        :opened="infoWindow.open"
        @closeclick="infoWindow.open=false">
        <div v-html="infoWindow.template"></div>
    </gmap-info-window>
    
  </gmap-map>

</template>
<script>
  export default {

    data () {
      //console.log('DATA');
      //console.log(this.$store.state.puentes);
      return {
        
        center: {lat: this.latitude, lng: this.longitude},
        mapOptions: {
          mapTypeControl: false,
          zoomControl: true,
          scaleControl: false,
          streetViewControl: false,
          rotateControl: false,
          fullscreenControl: false,
        },
        mapTypeId: "hybrid",
        infoWindow: {
          position: {lat: 0, lng: 0},
          open: false,
          template: ''
        },
        //markers: [{
        //  position: {lat: this.latitude, lng: this.longitude},
        //  title: this.title
        //}]
        //tooltipData: {
        //  name: false,
        //  puente: false,
        //  rio: false,
        //},
        //tooltip: false,
      }
    },

    methods: {
      getMarkerIcon(status){
        if(status == 1){
          //return 'https://developers.google.com/maps/documentation/javascript/examples/full/images/parking_lot_maps.png'
          //return '../../assets/dev/alerts/alertas-03-01.png'
          //return 'https://i.imgur.com/ZA6iiWJ.png'
          //const mapMarker = require('../../assets/dev/alerts/alertas-03-01.png');
          const mapMarker = 'https://i.imgur.com/ZA6iiWJ.png';

          var markerOptions = {
              url: mapMarker,
              //size: {width: 90, height: 60, f: 'px', b: 'px',},
              //scaledSize: {width: 45, height: 30, f: 'px', b: 'px',},
          };

          return markerOptions;
        }
        if(status == 2){
          const mapMarker = 'https://i.imgur.com/CaLqyC0.png';

          var markerOptions = {
              url: mapMarker,
          };

          return markerOptions;
        }
        if(status == 3){
          const mapMarker = 'https://i.imgur.com/fRCMawi.png';

          var markerOptions = {
              url: mapMarker,
          };

          return markerOptions;
        }
        if(status == 4){
          const mapMarker = 'https://i.imgur.com/Ti0G5VT.png';

          var markerOptions = {
              url: mapMarker,
          };

          return markerOptions;
        }
      },
      selectPuente(puente){
        //console.log(puente);
        //this.$store.dispatch("loadPacientes");
        this.window_open = true

        var puenteslist = this.$store.state.puentes;
        var puentedata = puenteslist.find( x => x.id === puente.id);

        var dialogData = {
          toggle: true,
          data: puentedata,
        }

        this.$emit('selectedPuente', dialogData);
      },
      showTooltip(puente){
        console.log('MOSTRAR TOOLTIP');
  
        var puenteslist = this.$store.state.puentes;
        var puentedata = puenteslist.find( x => x.id === puente.id);
        console.log(puentedata);

        this.infoWindow.position = { lat: puentedata['latitud'], lng: puentedata['longitud'] }
        this.infoWindow.template = '<v-card v-if="toggleTooltip" class="mx-auto my-0" max-width="344" outlined>' +
                                      '<v-row>' +
                                        '<v-col cols="6">' +
                                         '<v-img' +
                                            'height="200"' +
                                            'src="https://i.imgur.com/f656uB3.jpg"' +
                                          '></v-img>' +
                                        '</v-col>' +
                                        '<v-col cols="6">' +
                                          '<v-card-text class="pa-0 ma-0">' +
                                            '<v-card-title class="px-0 my-0">'+ puentedata['nombre'] +'</v-card-title>' +
                                            '<br>' +
                                            '<v-card-subtitle class="px-0 my-0">'+ puentedata['rio']['nombre'] +'</v-card-subtitle>' +
                                            '<br>' +
                                            '<v-card-subtitle class="px-0 my-0">'+ puentedata['rio']['region'] +' Región</v-card-subtitle>' +
                                            '<p class="pe-2"></p>' +
                                          '</v-card-text>' +
                                        '</v-col>' +
                                      '</v-row>' +
                                    '</v-card>';
        this.infoWindow.open = true
        
      },
      hideTooltip(puente){
        console.log('OCULTAR TOOLTIP');
        this.infoWindow.open = false
      },
    },

    computed: {
      markerList() { 
        return this.$store.getters.markerList;
      },
      puentes() { 
        return this.$store.state.puentes
      },
      markers() {
        return this.$store.state.markers
        /*
        var iconBase = "https://developers.google.com/maps/documentation/javascript/examples/full/images/";
        var icons = {
          parking: {
            icon: iconBase + "parking_lot_maps.png",
          },
          library: {
            icon: iconBase + "library_maps.png",
          },
          info: {
            icon: iconBase + "info-i_maps.png",
          },
        };

        var puenteslist = this.$store.state.puentes;
        var markerlist = [];
        var aux = {};
        for(var i = 0; i < puenteslist.length; i++){
          aux = {
            id: puenteslist[i]['id'],
            position: {lat: puenteslist[i]['latitud'], lng: puenteslist[i]['longitud']},
            //title: puenteslist[i]['nombre'],
            status: puenteslist[i]['status']
          }
          markerlist.push(aux);
        }
        console.log('MARCADORES');
        console.log(markerlist);
        return markerlist
        */
      }
    },

    watch: {
      markers: {
        deep: true,
        handler() {
          console.log("Oh, look, a new status!");
        }
      }
    },

    props: {
        latitude: Number,
        longitude: Number,
        title: String
    },

    mounted() {

      this.$refs.mapRef.$mapPromise.then((map) => {
        //map.panTo({lat: 1.38, lng: 103.80})
        map.mapTypeControl = false;



      })

    },
  }
</script>

<style lang="sass">
@import '../../assets/dev/css/mixin'
.btn-test
  border: solid 2px white
  background-color: #F44336
  position: absolute
  z-index: 4
  top: 70%
  left: 40%

.v-dialog
  width: auto
  margin: 0
  +for-ipad
    // width: 80%
.v-dialog__content
  justify-content: flex-start
  align-items: flex-start
.v-dialog:not(.v-dialog--fullscreen)
  max-height: 100%
.close-container
  z-index: 3
  position: absolute
</style>
