<template>
  <v-card dark class="drawer blue" :class="{ toggle: mini }">
    <v-icon
      @click="mini = !mini"
      color="white"
      class="ms-4 mt-3"
      style="cursor: pointer"
      
    >
      {{ mini ? "mdi-chevron-left" : "mdi-chevron-right" }}
    </v-icon>
    <v-divider></v-divider>
    <bridges />
    <sensors />
    <rivers />
    <clients/>
    <bridge-list />
    <support />

  </v-card>
</template>

<script>
import BridgeList from './NavMenu/BridgeList.vue';
import Bridges from './NavMenu/Bridges.vue';
import Clients from './NavMenu/Clients.vue';
import Rivers from './NavMenu/Rivers.vue';
import Sensors from './NavMenu/Sensors.vue';
import Support from './NavMenu/Support.vue';
export default {
  components: { Bridges, Sensors, Rivers, Clients, BridgeList, Support },
  data() {
    return {
      mini: true,
    }
  }
};
</script>
<style lang="sass" scoped>
.drawer
  position: absolute !important
  top: -30px !important
  right: 0px
  transition: .3s
.toggle
  right: -246px !important
</style>