<template>
  <v-container class="z-999" v-show="isModerator">

    <v-navigation-drawer
      class="overflow-inherit"
      color="blue"
      right
      absolute
      permanent
      :mini-variant.sync="mini"
      width="280"
      style="height:auto"
    >
        <v-icon
          @click.stop="mini = !mini"
          color="white"
          class="ms-4 mt-3"
          style="cursor:pointer">
          {{ mini ? "mdi-chevron-left" : "mdi-chevron-right" }}
        </v-icon>
      <!-- DEV
      <v-list>
        <v-list-item>
          <v-list-item-content class="pb-0">
            <v-btn icon :class="mini ? 'd-block' : 'd-none'">
              <v-img height="30" width="10" src="@/assets/dev/logo-icon.png" />
            </v-btn>
            <v-img
              height="60"
              :class="mini ? 'd-none' : 'd-block'"
              src="@/assets/dev/riverin-logo-bl.png"
            ></v-img>
          </v-list-item-content>
        </v-list-item>
        <User />
      </v-list>
      -->

      <v-divider></v-divider>

      <bridges  style="cursor:pointer" />
      <sensors  style="cursor:pointer" />
      <rivers   style="cursor:pointer" />
      <clients  style="cursor:pointer" />
      <bridge-list   style="cursor:pointer" />
      <support  style="cursor:pointer" />


    </v-navigation-drawer>
    <!-- <v-snackbar class="position-absolute" color="white" v-model="snackbar">
      <span class="blue--text">Puente agregado</span>
      <template v-slot:action="{ attrs }">
        <v-btn color="blue" v-bind="attrs" @click="snackbar = false">
          Cerrar
        </v-btn>
      </template>
    </v-snackbar> -->
    <v-snackbar
      v-model="showSnackbar.show"
    >
      {{showSnackbar.msg}}

      <template v-slot:action="{ attrs }">
        <v-btn
          :color="showSnackbar.color"
          text
          v-bind="attrs"
          @click="showSnackbar.show = false"
        >
          Cerrar
        </v-btn>
      </template>
    </v-snackbar>
  </v-container>
</template>

<script>
import { mapGetters } from 'vuex'
import { mapState } from 'vuex'
import Bridges from './NavMenu/Bridges.vue';
import Sensors from './NavMenu/Sensors.vue';
import Rivers from './NavMenu/Rivers.vue';
import Clients from './NavMenu/Clients.vue';
import Support from './NavMenu/Support.vue';


import AddBridge from './NavMenu/AddBridge.vue';
import AddClient from './NavMenu/AddClient.vue';
import AddRiver from './NavMenu/AddRiver.vue';
import AddSensor from './NavMenu/AddSensor.vue';
import BridgeList from './NavMenu/BridgeList.vue';
import BridgeList2 from './NavMenu/BridgeList2.vue';

import store from '@/store'

import User from "./User.vue";
export default {
  name: "NavDashboard",
  components: {
    User,
    Bridges,
    Sensors,
    Rivers,
    Clients,
    Support,
    AddBridge,
    AddClient,
    AddSensor,
    AddRiver,
    BridgeList,
    BridgeList2,
  },
  data() {
    return {
      snackbar: false,
      mini: true,
      items: [
        { title: "Ver Puentes Registrados", icon: "mdi-format-list-checkbox" },
      ],
      //isModerator: false,
    };
  },
  computed:{
    ...mapGetters(["showSnackbar"]),
    ...mapState({isModerator: 'isModerator'})
  },
  methods: {
    save() {
      this.snackbar = true;
    },
  },
};
</script>

<style lang="sass">
.overflow-inherit
  overflow: inherit
.toggle-btn
  position: absolute
  left: -35px
  top: 0
  z-index: 3
// .bg-drawer
//   background-image: url('@/assets/dev/bg-drawer.jpg')
//   background-size: cover
//   background-repeat: no-repeat
//   z-index: 5 !important

</style>
